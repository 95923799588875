<template>
  <div class="section">
    <div class="container">
      <div class="columns">
        <div class="column is-6 is-offset-3">
          <div class="box">
            <div class="is-size-4 has-text-weight-bold mb-2">Generate Points QR Code</div>

            <div class="field">
              <div class="control">
                <div class="field has-addons">
                  <div class="control is-expanded">
                    <input
                      class="input"
                      type="number"
                      v-model="points"
                      placeholder="Enter points"
                      @input="hideQR"
                    >
                  </div>
                  <div class="control">
                    <button
                      class="button is-primary"
                      @click="generateQR"
                      :disabled="!points"
                    >
                      Generate
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="qrValue" class="has-text-centered">
              <vue-qrcode :value="qrValue" />
              <p>Scan this</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode'
import { createHash } from 'crypto'

export default {
  name: 'FaciQrGenerator',
  components: {
    VueQrcode
  },
  data () {
    return {
      points: '',
      qrValue: null,
      faciKey: this.$route.params.faciKey,
      timer: null
    }
  },
  methods: {
    getTimeString (timestamp) {
      const date = new Date(timestamp)
      console.log(`${date.getUTCHours()}-${date.getUTCMinutes()}`)
      return `${date.getUTCHours()}-${date.getUTCMinutes()}`
    },
    generateQR () {
      try {
        // Get current minute timestamp
        const now = Math.floor(Date.now() / 60000) * 60000

        // Create hash using current time string (hour-minute)
        const hash = createHash('md5')
          .update(this.faciKey + this.getTimeString(now))
          .digest('hex')

        // Create the value by combining hash + points
        this.qrValue = hash + this.points
      } catch (error) {
        console.error('Error generating QR code:', error)
        alert('Error generating QR code. Please try again.')
      }
    },
    startTimer () {
      this.timer = setInterval(() => {
        this.generateQR()
      }, 90000) // 1.5 minutes
    },
    hideQR () {
      this.qrValue = null
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    }
  },
  watch: {
    qrValue (newVal) {
      if (newVal) {
        this.startTimer()
      }
    }
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>

<style scoped>
.qr-code {
  max-width: 200px;
  margin: 20px auto;
}
</style>
